import React from "react";
import { graphql } from "gatsby";
import { RevealOnMount } from "src/components/reveal-on-mount";

import GraphQLErrorList from "../components/graphql-error-list";
import { DesignPartners } from "../components/design-partners";

export const query = graphql`
	query DesignPartnersPageQuery {
		page: sanityDesignPartners(
			_id: { regex: "/(drafts.|)001designPartners/" }
		) {
			content: _rawContent(resolveReferences: { maxDepth: 10 })
		}
	}
`;

const DesignPartnersPage = (props) => {
	const { location, data, errors } = props;

	if (errors) {
		return <GraphQLErrorList errors={errors} />;
	}

	return (
		<RevealOnMount location={location}>
			<DesignPartners location={location} pageContext={data.page.content} />
		</RevealOnMount>
	);
};

export default DesignPartnersPage;
