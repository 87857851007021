import React, { useEffect } from "react";
import cx from "classnames";
import { Link as ScrollLink, Element as ScrollElement } from "react-scroll";
import { useSetPage } from "src/context/siteContext";
import { SEO } from "src/components/SEO";

import { ReactComponent as DesignPartnersSVG } from "src/images/svg/design-partners.svg";
import { ReactComponent as DesignPartnersSVGMobile } from "src/images/svg/design-partners-mobile.svg";

import styles from "./design-partners.module.css";

export interface DesignPartnersProps {
	pageContext: {
		main: {
			slug: {
				current: string;
			};
			title: string;
			partners: [
				{
					_key: string;
					name: string;
					headline: string;
					description: string;
					learnMoreLink: string;
				}
			];
		};
		meta: {};
	};
}

export const DesignPartners = ({ pageContext }: DesignPartnersProps) => {
	const setPage = useSetPage();
	const { main, meta } = pageContext;
	const { slug, title } = main;

	useEffect(() => {
		setPage(slug.current);
	}, [0]);

	const path = `${slug.current}`;
	return (
		<>
			<SEO metaInfo={meta} pagePath={path} />
			<div className={cx(slug.current, styles.designPartners)}>
				<div className={styles.designPartnersSVGContainer}>
					<DesignPartnersSVG className={styles.designPartnersSVG} />
					<DesignPartnersSVGMobile className={styles.designPartnersSVGMobile} />
				</div>
				<div className={styles.contentContainer}>
					<div className={styles.contentNav}>
						<ul className={styles.contentNavList}>
							{main.partners &&
								main.partners.map((partner) => {
									return (
										<li>
											<ScrollLink
												className={styles.contentNavLink}
												activeClass={styles.active}
												to={encodeURI(partner.name)}
												spy={true}
												smooth={true}
												offset={-33}
												duration={500}
											>
												<span
													className={cx(
														"sans sans--caps-11 medium",
														styles.contentNavLinkTitle
													)}
												>
													{partner.name}
												</span>
											</ScrollLink>
										</li>
									);
								})}
						</ul>
					</div>
					<div className={styles.content}>
						{main.partners &&
							main.partners.map((partner) => {
								return (
									<div className={styles.partner} key={partner._key}>
										<ScrollElement name={encodeURI(partner.name)}>
											<h2
												className={cx(
													"serif serif--60 lower",
													styles.partnerName
												)}
											>
												{partner.name}
											</h2>
											<div className={styles.partnerCopy}>
												<h3
													className={cx(
														"sans sans--caps-24",
														styles.partnerHeadline
													)}
												>
													{partner.headline}
												</h3>
												<p
													className={cx(
														"sans sans--body-14 light",
														styles.partnerDescription
													)}
												>
													{partner.description}
												</p>
												{partner.learnMoreLink && (
													<a
														className={cx(
															"sans sans--caps-11 medium",
															styles.learnMoreLink
														)}
														href={partner.learnMoreLink}
														target="_blank"
														rel="noopener noreferrer"
													>
														Learn More
													</a>
												)}
											</div>
										</ScrollElement>
									</div>
								);
							})}
					</div>
				</div>
			</div>
		</>
	);
};
