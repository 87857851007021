import React, { useState, useLayoutEffect, useEffect } from "react";
import cx from "classnames";

interface RevealOnMountProps {
	location: any;
	children: React.ReactNode;
}

export const RevealOnMount = ({ location, children }: RevealOnMountProps) => {
	const [shouldFadeIn, setShouldFadeIn] = useState(false);
	const [isMounted, setIsMounted] = useState(false);

	useLayoutEffect(() => {
		// only fade in on push state transitions
		// not when using browser back/forward buttons
		if (location && location.action === "PUSH") {
			setShouldFadeIn(true);
		}
	}, [location]);

	useEffect(() => {
		setIsMounted(true);
	}, [location]);

	return (
		<div
			className={cx(
				shouldFadeIn ? "fade-in-on-mount" : "",
				isMounted ? "is-mounted" : ""
			)}
		>
			{children}
		</div>
	);
};
