import React from "react";
import Helmet from "react-helmet";

const siteRoute = "https://theagnes001.com/";

export const SEO = ({
	pagePath,
	metaInfo,
}: {
	pagePath: string;
	metaInfo: {
		metaTitle?: string;
		metaDescription?: string;
		openGraphImage?: any;
		twitterImage?: any;
		twitterTitle?: string;
		openTitle?: string;
		openGraphDescription?: string;
		twitterDescription?: string;
	};
}) => {
	const title = metaInfo
		? metaInfo.metaTitle
			? metaInfo.metaTitle
			: "The Agnes 001"
		: "The Agnes 001";
	const metaDescription = metaInfo
		? metaInfo.metaDescription
			? metaInfo.metaDescription
			: "Where home is human."
		: "Where home is human.";
	const metaKeywords = "";
	const ogImage = metaInfo
		? metaInfo.openGraphImage?.asset?.url
			? metaInfo.openGraphImage.asset.url
			: ""
		: "";
	const twitterImage = metaInfo
		? metaInfo.twitterImage?.asset?.url
			? metaInfo.twitterImage.asset.url
			: ""
		: ogImage
		? ogImage
		: "";
	const openTitle = metaInfo
		? metaInfo.openTitle
			? metaInfo.openTitle
			: title
		: title;
	const openGraphDescription = metaInfo
		? metaInfo.openGraphDescription
			? metaInfo.openGraphDescription
			: metaDescription
		: metaDescription;
	const twitterTitle = metaInfo
		? metaInfo.twitterTitle
			? metaInfo.twitterTitle
			: title
		: title;
	const twitterDescription = metaInfo
		? metaInfo.twitterDescription
			? metaInfo.twitterDescription
			: metaDescription
		: metaDescription;
	return (
		<Helmet title={title}>
			<html lang="en" />
			<meta http-equiv="Accept-CH" content="DPR, Width, Viewport-Width" />
			<meta property="og:locale" content="en_US" />
			<meta name="description" content={metaDescription} />
			<meta name="keywords" content={metaKeywords} />
			<meta
				property="og:url"
				content={`${siteRoute}/${pagePath ? pagePath : ""}`}
			/>
			<meta property="og:image" content={ogImage} />
			<meta property="og:title" content={`${openTitle}`} />
			<meta property="og:site_name" content="001" />
			<meta property="og:description" content={openGraphDescription} />
			<meta name="twitter:card" content="summary_large_image" />
			<meta name="twitter:title" content={`${twitterTitle}`} />
			<meta name="twitter:image:src" content={twitterImage} />
			<meta name="twitter:description" content={twitterDescription} />
			<meta
				name="twitter:url"
				content={`${siteRoute}/${pagePath ? pagePath : ""}`}
			/>
		</Helmet>
	);
};
